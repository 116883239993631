import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PkClientesContext from "../../context/Login/PkClientesContext";
import OtpInput from "react-otp-input";
import { checkOtp } from "../../services/Otp/Otp";
import "./Otp.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function OtpVerification() {
  const { correoPersonalCliente, obtenerEmail } = useContext(PkClientesContext);

  const [otp, setOtp] = useState("");
  const [OtpError, setOtpError] = useState(false);
  const [mensaje, setMensaje] = useState(false);

  const handleChange = (otp) => setOtp(otp);

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const t = setTimeout(() => {
      if (seconds > 0) setSeconds(seconds - 1);

      if (minutes > 0 && seconds === 0) {
        setSeconds(59);
        setMinutes(minutes - 1);
      }

      if (!minutes && !seconds) obtenerEmail(null, null);
    }, 1000);

    return () => clearTimeout(t);
  }, [minutes, seconds]);

  useEffect(() => {
    const verificarOtp = async () => {
      try {
        const check = await checkOtp(correoPersonalCliente, otp);

        check.check || setOtpError(true);

        check.check && setMensaje(true);
      } catch (error) {
        console.error("Error verificando OTP:", error);
        setOtpError(true);
      }
    };

    if (otp.length === 6) {
      verificarOtp();
    } else {
      setOtpError(false);
    }
  }, [otp]);

  return (
    <>
      <Header title="Verificar Identidad" />
      <div className="otp">
        <div className="otp__container">
          <h3 className="otp__title">Te enviamos un código.</h3>
          <p className="otp__paragraph">
            Ingresa el código de 6 dígitos que enviamos a
            <span className="otp__span">{correoPersonalCliente}</span>
          </p>

          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            containerStyle="containerStyle"
            inputStyle="inputStyle"
            focusStyle="focusStyle"
            isInputNum
            hasErrored={OtpError}
            errorStyle="errorStyle"
            placeholder="******"
          />

          <span className="otp__timer">{`${
            minutes < 10 ? `0${minutes}` : minutes
          }:${seconds < 10 ? `0${seconds}` : seconds}`}</span>

          {correoPersonalCliente ? null : <Redirect to="/" />}
          {mensaje && <Redirect to="/new-password" />}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OtpVerification;

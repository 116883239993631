import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import BodyLogin from './BodyLogin';
import HeaderLogin from './HeaderLogin';
import Footer from '../Footer/Footer';
import PkClientesContext from '../../context/Login/PkClientesContext';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API;

const Login = () => {
  const { pkClienteInicial, primeraVez } = useContext(PkClientesContext);

  return (
    <>
      {pkClienteInicial ? (
        primeraVez === 1 ? (
          <Redirect to='/otp' />
        ) : (
          <Redirect to='/home' />
        )
      ) : (
        <>
          <HeaderLogin />
          <BodyLogin />
          <Footer />
        </>
      )}
    </>
  );
};

export default Login;

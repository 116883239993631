import axios from 'axios';
export const apiNewPassword = (datos) => {
  try {
    const dataNewPasswword = axios.post('/new-password', { datos });

    return dataNewPasswword;
  } catch (err) {
    console.log(err);
  }
};

import React, { useContext, useState } from "react";
import pkClienteContext from "../../context/Login/PkClientesContext";
import { sendDatosPdf } from "../../services/apiReportesPagosPdf/apiReportesPagosPdf.js";
import "../../styles/components/_tableReportesPagos.scss";
import Spinner from "../Spinner/Spinner";

const TablaReportesPagos = ({ datos, fechas }) => {
  const { nombreCliente, nit, bloqueado } = useContext(pkClienteContext);
  const [spinner, setSpinner] = useState(false);

  let valorTotalFacturas = 0;
  let numeroTotalFacturas = 0;
  let totalRecaudo = 0;

  datos.map(
    (item) => (
      (valorTotalFacturas += item.Valor),
      (numeroTotalFacturas += 1),
      item.Neto > 0 ? (totalRecaudo += item.Neto) : null
    )
  );

  const titles = [
    "factura",
    "ValorBruto",
    "Iva",
    "ValorTotal",
    "FechaFactura (AAAA-MM-DD)",
    "FechaPago (AAAA-MM-DD)",
    "ValorRecaudo",
    "Saldo",
    "Deducciones",
    "Ret.Ica",
    "Ret.Fuente",
    "Otros",
  ];

  const separadorMiles = (numero, separador = ".") => {
    if (typeof numero !== "number" || !Number.isInteger(numero)) {
      var parts = numero.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(",");
    }
    numero = String(numero);
    return numero.replace(/\B(?=(\d{3})+(?!\d))/g, separador);
  };

  const eliminadorSeparadores = (string) => {
    return string.replace(/_/g, " ");
  };

  const date = new Date();

  const sendDatos = async (index) => {
    if (index === "all") {
      const datosReciboPagosPdf = {
        fechaInicial: fechas[0].fechaInicial,
        fechaFinal: fechas[0].fechaFinal,
        fechaActual: `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`,
        nombreCompania: nombreCliente,
        nit: nit,
        pago: [datos],
      };
      setSpinner(true);
      await sendDatosPdf(datosReciboPagosPdf);
      setSpinner(false);
    } else {
      const datosReciboPagosPdf = {
        fechaActual: `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`,
        nombreCompania: nombreCliente,
        nit: nit,
        pago: [
          {
            Metodo_Pago: datos[index].Metodo_Pago,
            Cuenta: datos[index].Cuenta,
            Recibo: datos[index].Recibo,
            Fecha_Pago: datos[index].Fecha_Pago,
            Numero: datos[index].Numero,
            Fecha: datos[index].Fecha,
            Valor: datos[index].Valor,
            Saldo: datos[index].Saldo,
          },
        ],
      };
      sendDatosPdf(datosReciboPagosPdf);
    }
  };

  return (
    <div>
      <>
        <table className="table-container">
          <thead className="table-pagos__title-header">
            <tr>
              {titles
                ? titles.map((item, index) => <th key={index}>{item}</th>)
                : null}
            </tr>
          </thead>
          <tbody className="table-pagos__tbody">
            {!datos.mensaje
              ? datos.map((item, index) => (
                  <tr className="table-container__tr" key={index}>
                    <td className="table__tbody-tr-td">
                      {item.Numero != null
                        ? eliminadorSeparadores(item.Numero)
                        : 0}
                    </td>
                    <td className="table__tbody-tr-td">
                      {`$ ${
                        item.Valor_Bruto != null
                          ? separadorMiles(item.Valor_Bruto)
                          : 0
                      }`}
                    </td>
                    <td width="50px" className="table__tbody-tr-td">
                      {`$ ${
                        item.Valor_IVA != null
                          ? separadorMiles(item.Valor_IVA)
                          : 0
                      }`}
                    </td>
                    <td className="table__tbody-tr-td">
                      {`$ ${
                        item.Valor != null ? separadorMiles(item.Valor) : 0
                      }`}
                    </td>
                    <td className="table__tbody-tr-td">
                      {item.Fecha.substr(0, 10)}
                    </td>
                    <td className="table__tbody-tr-td">{item.Fecha_Pago}</td>
                    <td className="table__tbody-tr-td">
                      {`$ ${item.Neto != null ? separadorMiles(item.Neto) : 0}`}
                    </td>

                    <td className="table__tbody-tr-td">
                      {`$ ${
                        item.Saldo != null ? separadorMiles(item.Saldo) : 0
                      }`}
                    </td>

                    <td width="50px" className="table__tbody-tr-td">
                      {`$ ${
                        item.Tipo_Pago === "Saldo"
                          ? separadorMiles(
                              (item.Valor_Bruto * parseFloat(item.Retefuente)) /
                                100 +
                                item.Valor_Bruto * parseFloat(item.ICA) +
                                item.Valor_IVA +
                                parseFloat(item.Otros)
                            )
                          : "0"
                      }`}
                    </td>
                    <td width="80px" className="table__tbody-tr-td">
                      {`$ ${
                        item.Tipo_Pago === "Saldo"
                          ? separadorMiles(
                              item.Valor_Bruto * parseInt(item.ICA)
                            )
                          : "0"
                      }`}
                    </td>
                    <td className="table__tbody-tr-td">
                      {`$ ${
                        item.Tipo_Pago === "Saldo"
                          ? separadorMiles(
                              (item.Valor_Bruto * parseInt(item.Retefuente)) /
                                100
                            )
                          : "0"
                      }`}
                    </td>
                    <td width="50px" className="table__tbody-tr-td">
                      {`$ ${
                        item.Otros.length > 4
                          ? item.Tipo_Pago === "Saldo"
                            ? parseFloat(item.Otros)
                            : "0"
                          : item.Otros
                      }`}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
          <tfoot className="table__tfooter">
            <tr className="table-pagos__title-header">
              <th>TotalFacturas</th>
              <th></th>
              <th></th>
              <th>Total Valor Facturas</th>
              <th></th>
              <th></th>
              <th>Total Recaudo</th>

              <th></th>

              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className="table-pagos__tbody">
              <td>{numeroTotalFacturas}</td>
              <td></td>
              <td></td>
              <td>{`$ ${
                valorTotalFacturas != null
                  ? separadorMiles(valorTotalFacturas)
                  : 0
              }`}</td>
              <td></td>
              <td></td>
              <td>{`$ ${
                totalRecaudo != null ? separadorMiles(totalRecaudo) : 0
              }`}</td>

              <td></td>

              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <div className="container-button">
          {
            <button
              className="table__tbody-tr-button"
              onClick={(e) => {
                sendDatos("all");
              }}
            >
              Descargar Todos
            </button>
          }
        </div>
      </>
      {spinner === true ? <Spinner /> : null}
    </div>
  );
};

export default TablaReportesPagos;

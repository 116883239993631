import React, { useContext, useState } from 'react';
import AlertaContext from '../../context/Alerta/AlertaContext';
import LoginContext from '../../context/Login/PkClientesContext';
import { getCertificados } from '../../services/apiCertificados/apiCertificados';
import SideBar from '../layout/SideBar/SideBar';
import TablaCertificados from '../TablaCertificados/TablaCertificados';

const BodyCertificados = () => {
  const { UUIDSedes, pkClienteInicial } = useContext(LoginContext);
  const [datos, setDatos] = useState({
    fechaInicial: null,
    fechaFinal: null,
    pkClienteInicial,
    certificado: null,
    UUIDSedes,
  });

  const { MostrarAlerta } = useContext(AlertaContext);

  const [datosRecolecciones, setDatosRecolecciones] = useState(null);
  const guardarDatos = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const sendDatos = async (e) => {
    e.preventDefault();
    const datosObtenidos = await getCertificados(datos);
    if (datosObtenidos.mensaje) {
      MostrarAlerta(datosObtenidos.mensaje);
    } else {
      setDatosRecolecciones(await datosObtenidos.data);
    }
  };

  return (
    <div className='contenedor-app'>
      <SideBar />
      <div className='seccion-principal'>
        <form className='form-date' onSubmit={sendDatos}>
          <div className='contenedor'>
            <div>
              <label>Fecha Inicial</label>
              <br />
              <input onChange={guardarDatos} type='date' name='fechaInicial' />
            </div>
            <div>
              <label>Fecha Final</label>
              <br />

              <input onChange={guardarDatos} type='date' name='fechaFinal' />
            </div>
            <div>
              <label>No Factura</label>
              <br />
              <input
                onChange={guardarDatos}
                type='text'
                name='certificado'
                placeholder='Numero de Factura'
              />
            </div>
          </div>
          <input
            type='submit'
            className='btn btn-primario btn-block'
            value='Buscar'
          />
        </form>
        {datosRecolecciones ? (
          <TablaCertificados datos={datosRecolecciones} datosBusqueda={datos} />
        ) : (
          <h1>Sin Datos</h1>
        )}
      </div>
    </div>
  );
};

export default BodyCertificados;

import React from 'react';
import { getCertificadoURL } from '../../services/apiCertificadosPdf/apiCertificadosPdf';
import './VerificarCertificados.css';

const BodyVerificarCertificados = (props) => {
  const [url, setURL] = React.useState();
  const uuid = props.match.params.uuid;

  React.useEffect(() => {
    async function fetchData() {
      const res = await getCertificadoURL(uuid);
      setURL(res);
    }
    fetchData();
  }, [uuid]);

  return (
    <iframe title="pdf preview" src={url} className="verificar_certificados" />
  );
};

export default BodyVerificarCertificados;

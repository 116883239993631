import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { sendEmail } from "../../services/apiRecuperarContrasena/apiRecuperarContrasena";
import AlertaContext from "../../context/Alerta/AlertaContext";
import PkClientesContext from "../../context/Login/PkClientesContext";

const BodyRecuperarContrasena = () => {
  const { MostrarAlerta } = useContext(AlertaContext);
  const { obtenerEmail } = useContext(PkClientesContext);
  const [datos, setDatos] = useState({ nit: null });
  const [mensaje, setMensaje] = useState(false);

  const handleChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const sendDatos = async (e) => {
    e.preventDefault();

    if (true && datos.nit !== null && datos.nit.trim() !== "") {
      const { mensaje, Email, UUID } = await sendEmail(datos);
      obtenerEmail(Email, UUID);

      if (mensaje) {
        // MostrarAlerta(mensaje);
        setTimeout(() => {
          setMensaje(true);
        }, 2000);
      } else if (datos.error) {
        MostrarAlerta(datos.error);
      }
    }
  };
  return (
    <div className="form-usuario">
      <form className="password-form" onSubmit={sendDatos}>
        <h1 className="password-title">Busca por NIT de Empresa</h1>
        <div className="password-form__content-input">
          <label className="password-paragraph">
            Ingrese su <b>NIT</b> sin dígito de verificación y se le enviara al
            correo inscrito su contraseña:
          </label>
          <br />
          <input
            name="nit"
            onChange={handleChange}
            className="password-input"
            type="text"
            placeholder="Ingrese su NIT sin puntos,espacios o caracteres extraños"
          />
        </div>
        <br />
        <button className="button-send" type="submit">
          Recuperar Contraseña
        </button>
      </form>
      {mensaje && <Redirect to="/otp" />}
    </div>
  );
};

export default BodyRecuperarContrasena;

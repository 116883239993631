import axios from "axios";
export const sendDatosPdf = async (datos) => {
  const { fechaInicial, fechaFinal, nombreCliente, nit } = datos;


  await axios
    .post("/recoleccionesDatosPdf", {
      datos,
    })
    .then((datosRecoleccionesPdf) => {
      datosRecoleccionesPdf.data[0].fechaInicial = fechaInicial;
      datosRecoleccionesPdf.data[0].fechaFinal = fechaFinal;
      datosRecoleccionesPdf.data[0].nombreCliente = nombreCliente;
      datosRecoleccionesPdf.data[0].nit = nit;

      const dataDatosRecoleccionesPdf = datosRecoleccionesPdf.data;

      axios
        .post("/createPdfRecolecciones", {
          dataDatosRecoleccionesPdf,
        })
        .then(() => {
          axios
            .post("/getRecoleccionesPdf", datos, {
              responseType: "blob",
            })
            .then((responsePdf) => {
              const url = window.URL.createObjectURL(
                new Blob([responsePdf.data])
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "Recolecciones.pdf");
              document.body.appendChild(link);
              link.click();
            });
        });
    });
};

import HeaderRecuperarContrasena from './HeaderRecuperarContrasena';
import BodyRecuperarContrasena from './BodyRecuperarContrasena';
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const RecuperarContrasena = () => {
  return (
    <div>
      <Header title='Recuperar Contrasena' />
      <BodyRecuperarContrasena />
      <Footer />
    </div>
  );
};

export default RecuperarContrasena;

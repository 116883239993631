import React, { useContext, useEffect, useState } from 'react';
import AlertaContext from '../../context/Alerta/AlertaContext';
import pkClienteContext from '../../context/Login/PkClientesContext';
import { sendDatosPdf } from '../../services/apiPdf/apiPdf';
import Spinner from '../Spinner/Spinner';

const TablaRecolecciones = ({ datos, datosBusqueda }) => {
  const [datosTable, setDatosTable] = useState(null);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (datos) {
      setDatosTable(datos.data);
    }
  }, [datos]);

  const { nombreCliente, nit, UUIDSedes, bloqueado, UUIDSedeSelect } =
    useContext(pkClienteContext);
  const { MostrarAlerta } = useContext(AlertaContext);
  const titles = [
    'Fecha de Recoleccion  (AAAA-MM-DD)',
    'Direccion',
    'Sede',
    'Peso Total (KG)',
  ];

  const date = new Date();

  const sendDatos = async () => {
    const datosBusquedaDatosPdf = {
      UUIDSedes: UUIDSedeSelect,
      fechaFinal: datosBusqueda.fechaFinal,
      fechaInicial: datosBusqueda.fechaInicial,
      nit,
      nombreCliente,
    };

    setSpinner(true);
    await sendDatosPdf(datosBusquedaDatosPdf);
    setSpinner(false);
  };

  return (
    <div>
      <>
        <table className='table-container'>
          <thead className='table__title-header'>
            <tr className='table__title-header-items'>
              {titles
                ? titles.map((item, index) => <th key={index}>{item}</th>)
                : null}
            </tr>
          </thead>
          <tbody>
            {datosTable !== null
              ? datosTable.map((item, index) => (
                  <tr className='table-container__tr' key={index}>
                    <td className='table__tbody-tr-td'>
                      {item.client_signature_timestamp
                        ? item.client_signature_timestamp.substring(0, 10)
                        : ''}
                    </td>
                    <td className='table__tbody-tr-td'>
                      {item.company_address ? item.company_address : ''}
                    </td>
                    <td className='table__tbody-tr-td'>
                      {UUIDSedes.map((itemSede) =>
                        itemSede.UUID === item.UUID_Sede
                          ? itemSede.Nombre_Sede
                          : ''
                      )}
                    </td>
                    <td className='table__tbody-tr-td'>
                      {item.SUM_Weight.toString().match(/^\d+(?:\.\d{0,2})?/)}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </>
      <div className='container-button'>
        <button
          className='button'
          onClick={(e) => {
            sendDatos();
          }}>
          Descarga
        </button>
      </div>
      {spinner === true ? <Spinner /> : null}
    </div>
  );
};

export default TablaRecolecciones;

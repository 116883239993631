import axios from 'axios';

export const getFacturas = async (datosBusqueda) => {
  try {
    const datosFacturas = await axios.post('/facturas', {
      fechaInicial: datosBusqueda.fechaInicial,
      fechaFinal: datosBusqueda.fechaFinal,
      pkCliente: datosBusqueda.pkClienteInicial,
      factura: datosBusqueda.factura,
    });

    return await datosFacturas;
  } catch (error) {
    console.log(error);
  }
};

import React, { useEffect, useContext, useState } from "react";
import AlertaContext from "../../context/Alerta/AlertaContext";
import pkClienteContext from "../../context/Login/PkClientesContext";
import { getRecolecciones } from "../../services/apiRecolecciones/apiRecolecciones";
import SideBar from "../layout/SideBar/SideBar";
import TablaRecolecciones from "../TablaRecolecciones/TablaRecolecciones";

const BodyRecolecciones = () => {
  const { MostrarAlerta } = useContext(AlertaContext);
  const { pkClienteInicial, UUIDSedes, saveSite } =
    useContext(pkClienteContext);

  const [datosRecoleccion, setDatosRecoleccion] = useState(null);

  const [datosBusqueda, setDatosBusqueda] = useState({
    fechaInicial: null,
    fechaFinal: null,
    residue: null,
    UUIDSede: UUIDSedes,
    pkClienteInicial,
  });

  useEffect(() => {
    saveSite({
      UUIDSede: UUIDSedes.length > 1 ? UUIDSedes : UUIDSedes[0].UUID,
    });
  }, []);

  const guardarDatos = (e) => {
    e.preventDefault();

    if (e.target.name === "UUIDSede" && e.target.value === "") {
      saveSite({ [e.target.name]: UUIDSedes });
      setDatosBusqueda({
        ...datosBusqueda,
        [e.target.name]: UUIDSedes,
      });
    } else if (e.target.value === "") {
      setDatosBusqueda({
        ...datosBusqueda,
        [e.target.name]: null,
      });
    } else {
      if (e.target.name === "UUIDSede") {
        saveSite({ [e.target.name]: e.target.value });
      }
      setDatosBusqueda({
        ...datosBusqueda,
        [e.target.name]: e.target.value,
      });
    }
  };

  const buscar = async (e) => {
    e.preventDefault();
    if (
      datosBusqueda.residue ||
      (datosBusqueda.fechaInicial && datosBusqueda.fechaFinal) ||
      datosBusqueda.UUIDSede
    ) {
      const recoleccionesDatos = await getRecolecciones(datosBusqueda);
      if (
        (await recoleccionesDatos.mensaje) ||
        (await recoleccionesDatos.data.mensaje)
      ) {
        MostrarAlerta(
          (await recoleccionesDatos.mensaje) ||
            (await recoleccionesDatos.data.mensaje)
        );
      } else {
        setDatosRecoleccion(await recoleccionesDatos);
      }
    } else {
      MostrarAlerta(`Completa los datos`);
    }
  };

  return (
    <>
      <div className="contenedor-app">
        <SideBar />

        <div className="seccion-principal">
          <form className="form-date" onSubmit={buscar}>
            <div className="contenedor">
              <div>
                <label>Fecha Inicial</label>
                <br />
                <input
                  type="date"
                  name="fechaInicial"
                  id="finicialbuscar"
                  placeholder="Buscar por Fecha"
                  onChange={guardarDatos}
                />
              </div>
              <div>
                <label>Fecha Final</label>
                <br />

                <input
                  type="date"
                  name="fechaFinal"
                  id="ffinalbuscar"
                  placeholder="Buscar por Fecha"
                  onChange={guardarDatos}
                />
              </div>

              <div>
                <label htmlFor="UUIDSede">Seleccione la Sede</label>
                <br />
                <select name="UUIDSede" id="UUIDSede" onChange={guardarDatos}>
                  <option value="">Todas las sedes</option>
                  {UUIDSedes !== null
                    ? UUIDSedes.map((item) => (
                        <option key={item.UUID} value={item.UUID}>
                          {item.Nombre_Sede}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <input
              type="submit"
              className="btn btn-primario btn-block"
              value="Buscar"
            />
          </form>
          {datosRecoleccion && (
            <TablaRecolecciones
              datos={datosRecoleccion}
              datosBusqueda={datosBusqueda}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BodyRecolecciones;

import axios from 'axios';

const getCertificadoURL = async (UUID_Factura) => {
    const { data } = await axios.get(`/getCertificadoURL/${UUID_Factura}`);
    return data
};

const getCertificadoPdf = async (datosCertificadoPdf) => {
  const {
    fechaActual,
    fechaInicial,
    fechaFinal,
    nombreCompania,
    nit,
    sede,
    UUID_Factura,
    sedeName,
  } = datosCertificadoPdf;

  await axios
    .post('/certificados', {
      UUID_Factura,
    })
    .then(async (responseDatosCertificadoPdf) => {
      let blobPDF;

      if (typeof responseDatosCertificadoPdf.data !== 'string') {
        responseDatosCertificadoPdf.data[0].sedeName = sedeName;
        responseDatosCertificadoPdf.data[0].fechaActual = fechaActual;
        responseDatosCertificadoPdf.data[0].nombreCompania = nombreCompania;
        responseDatosCertificadoPdf.data[0].nit = nit;
        responseDatosCertificadoPdf.data[0].fechaFinal = fechaFinal;
        responseDatosCertificadoPdf.data[0].fechaInicial = fechaInicial;
        const dataCertificadoPdf = responseDatosCertificadoPdf.data;
        await axios
          .post('/createCertificadoPdf', {
            dataCertificadoPdf, UUID_Factura
          })
          .then(async () => {
            await axios({
              url: '/getCertificadoPdf',
              method: 'post',
              responseType: 'blob',
              data: { UUID_Factura, nit },
            })
            .then((responsePdf) => {
              return blobPDF = responsePdf
            });
          });
      }

       const link = document.createElement('a');
       const url = blobPDF ? window.URL.createObjectURL(new Blob([blobPDF.data])) : responseDatosCertificadoPdf.data;
       link.href = url;
       link.setAttribute('download', 'Certificado.pdf');
       document.body.appendChild(link);
       if (typeof responseDatosCertificadoPdf.data === "string") {
         link.target = '_blank';
       }
       link.click();
       return document.body.removeChild(link);

    });
};

export {getCertificadoURL,getCertificadoPdf}

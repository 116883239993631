import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import BodyVerificarCertificados from './BodyVerificarCertificados';

const VerificarCertificados = (props) => {
  return (
    <div>
      <Header title='Verificar certificado' />
          <BodyVerificarCertificados {...props} />
      <Footer />
    </div>
  );
};

export default VerificarCertificados;

import axios from 'axios';

export const getUUID = async (userEmail, userPassword) => {
  try {
    const datosUUID = await axios.post(
      '/login',
      { user: userEmail, password: userPassword },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return await datosUUID.data;
  } catch (error) {
    console.log(error);
  }
};
